import { useStaticQuery, graphql } from 'gatsby';

const infoGet = () => {
  const newsdataquery = useStaticQuery(graphql
    `query{
        allMicrocmsBanquetPlanRph (sort: {fields: sortIndex, order: ASC}){
          edges {
            node {
              title
              publishedAt
              banquetPlanRphId
              period
              fee
              description
              eyecatch {
                url
                height
                width
              }
            }
          }
        }
      }`
  )
  const newsdata = newsdataquery.allMicrocmsBanquetPlanRph.edges
  return newsdata
}

export default infoGet