import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInfinitySlider,
  COverflowTable,
  CSimpleCard,
  CTable,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  LBanquetContact,
  CBanquetPlan,
  LModelCase
} from '../../../components/_index';
import banquetPlanGet from "../../../utils/banquetPlan-get"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        type='ja'
        data={{
          title: {
            main: 'ご宴会プラン',
            sub: (
              <>
                PLAN
              </>
            ),
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/plan/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/plan/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="l_sect u_pb0">
        <LWrap>
          <CBanquetPlan data={banquetPlanGet()} />
        </LWrap>
      </div>
      <LModelCase exClass='l_sect u_pb0' />
      <LVenue />

      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
